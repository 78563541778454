<template>
  <div>
    <div class="row page-title-header">
      <div class="col-12">
        <div class="page-header">
          <b-breadcrumb class="m-0">
            <b-breadcrumb-item
              :to="{
                name: 'DashboardHome',
              }"
            >
              <i class="fa fa-home"></i>
            </b-breadcrumb-item>

            <b-breadcrumb-item
              :to="{
                name: 'OrganizationHome',
              }"
            >
              組織資料設定
            </b-breadcrumb-item>
            <b-breadcrumb-item active>每日活動列表</b-breadcrumb-item>
          </b-breadcrumb>
        </div>
      </div>
    </div>
    <b-card>
      <div class="d-flex mb-4 mb-xl-2 justify-content-between">
        <div
          class="
            d-flex
            flex-column flex-xl-row
            align-items-start align-items-xl-center
          "
        >
          <b-button
            v-if="checkPermission([consts.DAILY_ACT_LIST_CREATE])"
            class="flex-shrink-0 mb-2 mb-xl-0"
            variant="primary"
            :to="{ name: 'DailyActivityCreate' }"
            ><i class="fa fa-plus"></i>新增每日活動</b-button
          >
        </div>
      </div>
      <b-card-text>
        <div class="row">
          <div class="col-12">
            <b-overlay :show="showLoading" rounded="sm">
              <b-table
                striped
                hover
                responsive
                :items="dailyActivities"
                :fields="fields"
              >
                <template #cell(actions)="data">
                  <b-button
                    variant="inverse-primary"
                    :to="{
                      name: 'DailyActivityView',
                      params: { activity_id: data.item.id },
                    }"
                  >
                    查看
                  </b-button>
                  <b-button
                    v-if="checkPermission([consts.DAILY_ACT_LIST_MODIFY])"
                    variant="inverse-warning"
                    :to="{
                      name: 'DailyActivityEdit',
                      params: { activity_id: data.item.id },
                    }"
                  >
                    編輯
                  </b-button>
                  <b-button
                    v-if="checkPermission([consts.DAILY_ACT_LIST_MODIFY])"
                    variant="inverse-danger"
                    @click="deleteDailyActivity(data.item)"
                  >
                    刪除
                  </b-button>
                </template>
              </b-table>
            </b-overlay>
          </div>
        </div>
        <div class="d-flex justify-content-center" style="margin-top: 80px">
          <b-pagination
            v-model="currentPage"
            :total-rows="totalRows"
            :per-page="perPage"
            align="center"
            @change="getDailyActivities"
          ></b-pagination>
        </div>
      </b-card-text>
    </b-card>
  </div>
</template>

<script>
import { mapState } from "vuex";
import dailyActivityApi from "@/apis/daily-activity";
import PermissionChecker from '@/utils/PermissionChecker'
import * as consts from '@/consts'
import { format } from "date-fns";

export default {
  data() {
    return {
      consts: consts,
      perPage: 10,
      currentPage: 1,
      totalRows: 1,
      showLoading: true,
      dailyActivities: [],
      dailyActivity: null,
      fields: [
        {
          key: "activity_code",
          label: "每日活動代碼",
        },
        {
          key: "activity_at",
          label: "活動時間",
          formatter: (value) => {
            return format(new Date(value), 'yyyy-MM-dd');
          },
        },
        {
          key: "created_at",
          label: "建立時間",
          formatter: (value) => {
            return format(new Date(value), 'yyyy-MM-dd HH:mm:ss');
          },
        },
        {
          key: "actions",
          label: "操作",
        },
      ],
    };
  },
  computed: {
    ...mapState("general", {
      organization: (state) => state.organization,
    }),
  },
  watch: {
    currentPage() {
      this.getDailyActivities();
    }
  },
  mounted() {
    this.init();
  },
  methods: {
    async init() {
      await this.getDailyActivities();
    },
    async getDailyActivities() {
      this.showLoading = true;
      try {
        let params = {
          per_page: this.perPage,
          page: this.currentPage,
          dailyActivity: this.dailyActivity,
        };

        const { data } = await dailyActivityApi.getDailyActivities(this.organization, params);
        this.dailyActivities = data.data;
        this.totalRows = data.meta.total;
      } catch (error) {
        console.error(error);
        if (error.status !== 401) {
          this.$swal.fire({
            title: "錯誤",
            type: "error",
            text: "取得每日活動資料錯誤",
          });
        }
      }
      this.showLoading = false;
    },
    async deleteDailyActivity(dailyActivity) {
      await this.$swal
        .fire({
          title: "你確定要刪除嗎？",
          html: `
            <div class="d-block">
              <div class="my-3">
                <div>每日活動：${dailyActivity.activity_code}</div>
              </div>
              <div>刪除後，相關資料將無法回復，<br/>請您確認！</div>
            </div>`,
          type: "warning",
          confirmButtonText: "確定刪除",
          cancelButtonText: "不刪除",
          showCancelButton: true,
          confirmButtonColor: "#d33",
          cancelButtonColor: "#3085d6",
          reverseButtons: true,
        })
        .then(async (result) => {
          if (result.value) {
            let response = await dailyActivityApi.removeDailyActivity(this.organization, dailyActivity);
            if (response.status === 200) {
              this.$swal.fire({
                title: "成功",
                type: "success",
                text: "成功刪除 ",
              });
              this.getDailyActivities();
            } else {
              if (response.data.message) {
                this.$swal.fire({
                  title: '錯誤',
                  type: "error",
                  text: response.data.message,
                });
              }
            }
          }
        });
    },
    checkPermission (permissions) {
      const checker = new PermissionChecker;
      return checker.check(permissions);
    }
  },
};
</script>

<style scoped>
.btn + .btn {
  margin-left: 0.5rem;
}

.vdp-datepicker {
  min-width: 15rem;
}
</style>
